import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const NOTIFICATION_API_URL = '/api/notification'; 

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

 saveNotif(user_id: number, week:number): Observable<any> {
  const payload = { user_id,week};
  return this.http.post(`${NOTIFICATION_API_URL}/store`, payload);
}

getNotifCount(): Observable<any> {
  return this.http.get(`${NOTIFICATION_API_URL}/count`);
}

sendNotif(): Observable<any> {
return this.http.get(`${NOTIFICATION_API_URL}/send`);
}

}
