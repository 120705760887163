import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './view/auth/login/login.component';
import {AgentComponent} from './view/administration/staff-management/agent/agent.component';
import {JobComponent} from './view/administration/staff-management/job/job.component';
import {SkillComponent} from './view/administration/staff-management/skill/skill.component';
import {ResetPasswordComponent} from './view/auth/reset-password/reset-password.component';
import {CompanyComponent} from './view/administration/company/company.component';
import {ServiceComponent} from './view/administration/company/service/service.component';
import {ForgotPasswordComponent} from './view/auth/forgot-password/forgot-password.component';
import {ResetPasswordGuard} from './guard/reset-password.guard';
import {ChangePasswordComponent} from './view/auth/change-password/change-password.component';
import {OperatingLocationComponent} from './view/administration/site/operating-location/operating-location.component';
import {OperatingSiteComponent} from './view/administration/site/operating-site/operating-site.component';
import {PlanningComponent} from './view/specific-view/planning/planning.component';
import {SiteStatusPlanningComponent} from './view/specific-view/site-status-planning/site-status-planning.component';
import {AstreinteComponent} from './view/specific-view/astreinte/astreinte.component';
import {BankHolidayComponent} from './view/specific-view/bank-holiday/bank-holiday.component';
import {TokenValidGuard} from './guard/token-valid.guard';
import {PlanningConsultComponent} from './view/specific-view/planning-consult/planning-consult.component';
import {IndisponibiltiesComponent} from "./view/specific-view/indisponibilties/indisponibilties.component";
import {PlanificatorGuard} from "./guard/planificator.guard";
import {EmailEditComponent} from './view/administration/email-edit/email-edit.component';
import {SmsManagementComponent} from './view/administration/sms-management/sms-management.component';
import {EmailManagementComponent} from './view/administration/email-management/email-management.component';
// TODO infinite loop with canActivate on login
const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'password/reset',
        component: ResetPasswordComponent,
        canActivate: [ResetPasswordGuard],
      },
    ],
  },
  {
    path: 'user',
    canActivateChild: [TokenValidGuard],
    children: [
      {
        path: 'change-password',
        component: ChangePasswordComponent,
      },
    ],
  },
  {
    path: 'admin',
    canActivateChild: [TokenValidGuard],
    children: [
      {
        path: 'staff',
        children: [
          {
            path: 'agent',
            component: AgentComponent,
          },
          {
            path: 'job',
            component: JobComponent,
          },
          {
            path: 'skill',
            component: SkillComponent,
          },
        ],
      },
      {
        path: 'company',
        children: [
          {
            path: '',
            component: CompanyComponent,
          },
          {
            path: 'service',
            component: ServiceComponent,
          },
        ],
      },
      {
        path: 'site',
        children: [
          {
            path: 'operating-location',
            component: OperatingLocationComponent,
          },
          {
            path: 'operating-site',
            component: OperatingSiteComponent,
          },
        ],
      },
      {
        path: 'edit-email/:id',
        component: EmailEditComponent,
      },
      {
        path: 'email-management',
        component: EmailManagementComponent,
      },
      {
        path: 'sms-management',
        component: SmsManagementComponent,
      },
    ],
  },
  {
    path: 'view',
    canActivateChild: [TokenValidGuard],
    children: [
      {
        
        path: 'tools',
        children: [
          {
            path: 'planificator',
            component: PlanningComponent,
            canActivate: [PlanificatorGuard],

          },
          {
            path: 'site-status',
            component: SiteStatusPlanningComponent,
          },
        ],
      },
      {
        path: 'astreinte',
        component: AstreinteComponent,
      },
      {
        path: 'abscence',
        component: IndisponibiltiesComponent,
      },
      {
        path: 'bank-holiday',
        component: BankHolidayComponent,
      },
      {
        path: 'planning-consult',
        component: PlanningConsultComponent,
      },
      

    ],
    
  },
  // TODO handle 404, connected and not connected
  {
    path: '*',
    redirectTo: '/auth/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
