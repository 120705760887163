import {Injectable} from '@angular/core';
import {ActivityTypeService} from '../admin/activity-type.service';
import {AgentService} from '../admin/agent.service';
import {CompanyService} from '../admin/company.service';
import {DayService} from '../admin/day.service';
import {FetchingService} from '../admin/fetching.service';
import {JobService} from '../admin/job.service';
import {OperatingLocationService} from '../admin/operating-location.service';
import {WorkScheduleService} from '../admin/work-schedule.service';
import {DateFormatted, PlanningService} from '../view/planning.service';
import {DAYS_QUANTITY_FOR_PLANIFICATOR, OPERATING_LOCATION_ID,} from '../../../constant/number.constants';
import {map, take} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, forkJoin, Observable, ReplaySubject,} from 'rxjs';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {Agent} from 'src/app/model/agent.model';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {ActivityType} from 'src/app/model/activity-type.model';
import {Event, EventActivityLine} from 'src/app/model/event.model';
import {Day} from 'src/app/model/day.model';
import {Job, sortJob} from 'src/app/model/job.model';
import {Company} from 'src/app/model/company.model';
import {OperatingLocation} from 'src/app/model/operating-location.model';
import {DateService} from '../date.service';
import {Skill} from 'src/app/model/skill.model';
import {Service} from 'src/app/model/service.model';
import {SkillService} from '../admin/skill.service';
import {ServiceService} from '../admin/service.service';
import {BankHolidayPlanningService} from '../view/bank-holiday-planning.service';
import {BankHolidayPlanning} from 'src/app/model/bank-holiday-planning.model';
import {PlanningVersionService} from '../view/planning-version.service';
import {EventVersion} from 'src/app/model/event-version.model';
import {HoursPayedService} from '../view/hours-payed.service';
import {HourPayed} from 'src/app/model/hour-payed.model';
import {SiteStatusPlanningService} from "../view/site-status-planning.service";
import {SiteStatus, SiteStatusPlanning} from "../../model/site-status.model";
import {SiteStatusService} from "../view/site-status.service";
import {HttpClient} from '@angular/common/http';
import { NotificationService } from '../../service/admin/notification.service';

const WORK_SCHEDULE_URL = '/api/work_schedule';

@Injectable({
  providedIn: 'root',
})
export class PlanningFacadeService {
  private readonly _sitesStatusPlanningByOperatingSite$: ReplaySubject<Map<number, Map<string, SiteStatus>>> = new ReplaySubject<Map<number, Map<string, SiteStatus>>>(1);
  private readonly _sitesStatus$: ReplaySubject<SiteStatus[]> = new ReplaySubject<SiteStatus[]>(1);
  private readonly _sitesStatusesPlanningRecap$: ReplaySubject<Map<string, number>> = new ReplaySubject<Map<string, number>>(1);
  private readonly _agentMultiSelected$: BehaviorSubject<{
    agent: Agent;
    job: Job | null;
  } | null> = new BehaviorSubject<{ agent: Agent; job: Job | null } | null>(null);
  private readonly _operatingLocationSelected$: BehaviorSubject<OperatingLocation | null> = new BehaviorSubject<OperatingLocation | null>(null);
  private readonly _operatingSitesAvailable$: ReplaySubject<OperatingSite[]> = new ReplaySubject<OperatingSite[]>(1);
  private readonly _selectedVersion$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private readonly _isLatestEventVersionSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly _cellsToBeCopiedData$: BehaviorSubject<{
    agent: Agent;
    job: Job | null;
  } | null> = new BehaviorSubject<{ agent: Agent; job: Job | null } | null>(null);


  constructor(private readonly _agentService: AgentService,
              protected readonly _httpClient: HttpClient,
              private readonly _workScheduleService: WorkScheduleService,
              private readonly _activityTypeService: ActivityTypeService,
              private readonly _planningService: PlanningService,
              private readonly _fetchingService: FetchingService,
              private readonly _dayService: DayService,
              private readonly _jobService: JobService,
              private readonly _skillService: SkillService,
              private readonly _companyService: CompanyService,
              private readonly _serviceService: ServiceService,
              private notificationService: NotificationService,
              private readonly _operatingLocationService: OperatingLocationService,
              private readonly _dateService: DateService,
              private readonly _bankHolidayPlanningService: BankHolidayPlanningService,
              private readonly _planningVersionService: PlanningVersionService,
              private readonly _hoursPayedService: HoursPayedService,
              private readonly _siteStatusPlanningService: SiteStatusPlanningService,
              private readonly _siteStatusService: SiteStatusService) {
  }

  selectDefaultOperatingLocation(): void {
    this._operatingLocationService.globalData$
      .pipe(
        map(
          (locations) =>
            locations.filter(
              ({id}) => `${id}` == `${OPERATING_LOCATION_ID}`
            )[0]
        )
      )
      .subscribe((location) => {
        if (!!location) {
          this.operatingLocationSelected = location;
        }
      });
  }

  get agents$(): Observable<Agent[]> {
    return this._agentService.globalData$;
    
    /*return this._agentService.globalData$
      .pipe(
        map((agents => agents.filter((agent: Agent) => agent.is_planificateur)))
      );*/

  }

  
  

  get agentMultiSelected$(): Observable<{ agent: Agent; job: Job | null; } | null> {
    return this._agentMultiSelected$;
  }

  get workSchedules$(): Observable<WorkSchedule[]> {
    return this._workScheduleService.globalData$;
  }

  get activityTypes$(): Observable<ActivityType[]> {
    return this._activityTypeService.globalData$;
  }

  get plannings$(): Observable<Event[]> {
    return this._planningService.globalData$;

  }

  get planningsByAgent$(): Observable<Map<number, { [date: string]: Event[] }>> {
    return this.plannings$.pipe(
      map((events: Event[]) =>
        events.reduce((map, event) => {
          const id: number = event.user.id;
          if (map.has(id)) {
            map.get(id)![event.date]?.push(event);
          } else {
            const emptyObject = this._generateEmptyEventByDay();
            emptyObject[event.date]?.push(event);
            map.set(id, emptyObject);
          }

          return map;
        }, new Map<number, { [date: string]: Event[] }>())
      )
    );
  }

  get fetching$(): Observable<boolean> {
    return this._fetchingService.fetching$;
  }

  get days$(): Observable<Day[]> {
    return this._dayService.globalData$;
  }

  get jobs$(): Observable<Job[]> {
    return this._jobService.globalData$.pipe(
      map((jobs: Job[]) => jobs.sort(sortJob))
    );
  }

  get skills$(): Observable<Skill[]> {
    return this._skillService.globalData$;
  }

  get companies$(): Observable<Company[]> {
    return this._companyService.globalData$;
  }

  get services$(): Observable<Service[]> {
    return this._serviceService.globalData$;
  }


  get operatingLocationSelected$(): Observable<OperatingLocation | null> {
    return this._operatingLocationSelected$;
  }

  get operatingSitesAvailable$(): Observable<OperatingSite[]> {
    return this._operatingSitesAvailable$;
  }

  get dates$(): Observable<Date[]> {
    return this._dateService.dates$;
  }

  get datesString$(): Observable<string[]> {
    return this._dateService.datesString$;
  }

  get weekSelected$(): Observable<number> {
    return this._dateService.weekSelected$;
  }

  get yearSelected$(): Observable<number> {
    return this._dateService.yearSelected$;
  }

  get sitesStatusPlanningByOperatingSite$(): Observable<Map<number, Map<string, SiteStatus>>> {
    return this._sitesStatusPlanningByOperatingSite$
  }

  get sitesStatus$(): Observable<SiteStatus[]> {
    return this._sitesStatus$
  }

  get sitesStatusesPlanningRecap$(): Observable<Map<string, number>> {
    return this._sitesStatusesPlanningRecap$
  }

  get daysOff$(): Observable<BankHolidayPlanning[]> {
    return this._bankHolidayPlanningService.globalData$;
  }

  get versions$(): Observable<EventVersion[]> {
    return this._planningVersionService.globalData$;
  }

  get versionSelected$(): Observable<number | null> {
    return this._selectedVersion$;
  }

  get versionSelected(): number | null {
    return this._selectedVersion$.getValue();
  }

  set versionSelected(version: number | null) {
    this._selectedVersion$.next(version);
    this.getEventsGlobal();
  }

  get isLatestEventVersionSelected$(): Observable<boolean> {
    return this._isLatestEventVersionSelected$;
  }

  set isLatestEventVersionSelected(isSelected: boolean) {
    this._isLatestEventVersionSelected$.next(isSelected);
  }

  set year(newYear: number) {
    this._dateService.year = newYear;
    this._dateService.week = 1;
    this._dateService.getWeekDatesOnGivenWeekNumberGlobal$();
    this.getEventsGlobal();
  }

  get year(): number {
    return this._dateService.yearSelected$.getValue();
  }

  get week(): number {
    return this._dateService.weekSelected$.getValue();
  }

  set week(newWeek: number) {
    this._dateService.week = newWeek;
    this._dateService.getWeekDatesOnGivenWeekNumberGlobal$();
    this.getEventsGlobal();
  }

  set agentMultiSelected(data: { agent: Agent; job: Job | null }) {
    this._agentMultiSelected$.next(data);
  }

  set fetching(value: boolean) {
    this._fetchingService.fetching = value;
  }

  get multipleDataForResourcePlanificator$(): Observable<any> {
    return forkJoin([
      this.skills$.pipe(take(1)),
      this.companies$.pipe(take(1)),
      this.services$.pipe(take(1)),
      this.days$.pipe(
        take(1),
        map((days) => days.map((d) => d.getName()))
      ),
    ]).pipe(take(1));
  }

  get hoursPayed$(): Observable<HourPayed[]> {
    return this._hoursPayedService.globalData$;
  }

  initialize(): void {
    this._dateService.daysWanted = DAYS_QUANTITY_FOR_PLANIFICATOR;
    this.getEventsGlobal();
    this._agentService.getAllWithRelationsGlobal$([
      'company',
      'jobs',
      'jobs.activityType',
      'skills',
      'services',
    ]);
    this._operatingLocationService.getAllWithRelationsGlobal$([
      'operatingSites',
    ]);
    this._dayService.getAllWithRelationsGlobal$(['*']);
    this._jobService.getAllWithRelationsGlobal$(['activityType']);
    this._workScheduleService.getAllWithRelationsGlobal$(['*']);
    this._activityTypeService.getAllWithRelationsGlobal$(['*']);
    this._skillService.getAllWithRelationsGlobal$(['*']);
    this._companyService.getAllWithRelationsGlobal$(['services']);
    this._serviceService.getAllWithRelationsGlobal$(['*']);
    this._dateService.getWeekDatesOnGivenWeekNumberGlobal$();
    this._siteStatusService.getAllWithRelations$(['*'])
      .subscribe((sitesStatus: SiteStatus[]) => this._sitesStatus$.next(sitesStatus));

    combineLatest([
      this.weekSelected$,
      this.yearSelected$,
      this.versionSelected$
    ]).subscribe(([weekSelected, yearSelected, versionSelected]) => {
      //console.log("versionSelected ====== "+ versionSelected)
      this._siteStatusPlanningService.getOnWeek$(weekSelected, yearSelected, 1, null)
        .subscribe((plannings: SiteStatusPlanning[]) => {
          const map: Map<number, Map<string, SiteStatus>> = new Map();
          plannings.forEach(siteStatusPlanning => {
            const operatingSiteId = siteStatusPlanning.operating_site.id;
            if (!map.has(operatingSiteId)) {
              map.set(operatingSiteId, new Map().set(siteStatusPlanning.date, siteStatusPlanning.site_status))
            } else {
              map.set(operatingSiteId, map.get(operatingSiteId)!.set(siteStatusPlanning.date, siteStatusPlanning.site_status))
            }
          })
          this._sitesStatusPlanningByOperatingSite$.next(map)
        })

      this._siteStatusPlanningService.geRecapTable$(weekSelected, yearSelected, versionSelected)
        .subscribe(_ => {
          const map: Map<string, number> = new Map();
          Object.keys(_).forEach(key => {
            map.set(key, _[key]);
          })
          this._sitesStatusesPlanningRecap$.next(map)
        })
    })


    this._bankHolidayPlanningService.getOnYearGlobal$(
      this._dateService.yearSelected$.getValue()
    );
    this.plannings$.subscribe((_) => {
      this._planningVersionService.getAllOnYearAndWeekGlobal$(
        this._dateService.yearSelected$.getValue(),
        this._dateService.weekSelected$.getValue()
      );
      this._hoursPayedService.getAllOnYearAndWeekGlobal$(
        this._dateService.yearSelected$.getValue(),
        this._dateService.weekSelected$.getValue(),
        this._selectedVersion$.getValue()
      );
    });
  }

  set operatingLocationSelected(operatingLocation: OperatingLocation) {
    this._operatingLocationSelected$.next(operatingLocation);
    this._operatingSitesAvailable$.next(operatingLocation.operating_sites);
  }

  getAgentsForJob$(job: Job): Observable<Agent[]> {
    return this.agents$.pipe(
      map((agents) =>
        agents.filter(({jobs}) => jobs?.some(({id}) => id == job.id))
      )
    );
  }


  getEventsForAgent$({id}: Agent): Observable<{ [date: string]: Event[] }> {
    return this.planningsByAgent$.pipe(
      map((data) => {
        if (data.has(id)) {
          return data.get(id)!;
        }

        return this._generateEmptyEventByDay();
      })
    );
  }

  getAgentsByService$(search$: Observable<string>,
                      job$: Observable<number>): Observable<Map<string, Agent[]>> {
    return combineLatest([search$, this.agents$, job$]).pipe(
      map(([search, agents, job]) =>
        agents
          .filter((agent: Agent) =>
            agent
              .getName()
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          )
          .filter(
            (agent: Agent) => !job || agent.jobs?.some(({id}) => job == id)
          )
          .reduce((map, next: Agent) => {
            next.services?.forEach(({name}) => {
              if (!map.has(name)) {
                map.set(name, []);
              }
              map.get(name)!.push(next);
            });
            return map;
          }, new Map())
      )
    );
  }

  getHoursPayedForAgent$(agentId: number): Observable<HourPayed[]> {
    return this._hoursPayedService.globalData$.pipe(
      map((data: HourPayed[]) =>
        data.filter(({agent_id}) => agent_id == agentId)
      )
    );
  }

  getHoursPayedForJob$({id, name}: Job): Observable<HourPayed[]> {
    return combineLatest([
      this.agents$,
      this._hoursPayedService.globalData$,
    ]).pipe(
      map(([agents, hoursPayed]) => {
        const ids: number[] = agents
          .filter(({jobs}: Agent) =>
            jobs?.some((job: Job) => job.name == name)
          )
          .map(({id}) => id);
        return hoursPayed.filter(({agent_id, job_id}) => ids.includes(agent_id) && job_id === id);
      })
    );
  }

  getDayForGivenDate$(date: string): Observable<Day> {
    return this._dayService.getDayForGivenDate$(date);
  }

  deleteEvent$(event: Event): Promise<void> {
    return this._planningService.deleteGlobal$(event);
  }

  saveEvent$(lines: EventActivityLine[],
             agent: Agent,
             site: OperatingSite,
             job: Job,
             skills: Skill[],
             services: Service[],
             dates: DateFormatted[],
             comment: string | null,
             version: number | null): Promise<void> {
    return this._planningService.saveEventGlobal$(
      lines,
      agent,
      site,
      job,
      skills,
      services,
      dates,
      comment,
      version,
      this.week
    );
  }

  updateEvent$(lines: EventActivityLine[],
               id: number,
               agent: Agent,
               site: OperatingSite,
               job: Job,
               skills: Skill[],
               services: Service[],
               date: string,
               day: Day,
               comment: string | null,
               version: number | null): Promise<void> {
    return this._planningService.updateEventGlobal$(
      lines,
      id,
      agent,
      site,
      job,
      skills,
      services,
      date,
      day,
      comment,
      version,
      this.week
    );
  }

  duplicateEvents$(events: Event[],
                   agents: Agent[]): Promise<{ [date: string]: Agent[] }> {
    return this._planningService.duplicateEventsGlobal$(events, agents);
  }

  deleteMultiple(agent: Agent,
                 year: number = this._dateService.yearSelected$.getValue(),
                 week: number = this._dateService.weekSelected$.getValue()): void {
    this._planningService
      .deleteMultiple$(agent, year, week, this._selectedVersion$.getValue())
      .then(() => this.getEventsGlobal())
      .catch((err) => console.log(err));
  }

  versionate$(): Promise<EventVersion> {
    return this._planningVersionService.versionateGlobal$(
      this._dateService.yearSelected$.getValue(),
      this._dateService.weekSelected$.getValue(),
      this._selectedVersion$.getValue()
    );
  }

  publishVersion$(): Promise<any> {
    return this._planningVersionService.publishVersion$(
      this._dateService.yearSelected$.getValue(),
      this._dateService.weekSelected$.getValue(),
      this._selectedVersion$.getValue());
  }

  getEventsGlobal(): void {
    this._planningService.getOnWeekGlobal$(
      OPERATING_LOCATION_ID,
      this._dateService.weekSelected$.getValue(),
      this._dateService.yearSelected$.getValue(),
      this._selectedVersion$.getValue()
    );
  }

  private _generateEmptyEventByDay(): { [date: string]: Event[] } {
    return this._dateService.dates$
      .getValue()
      .map((date) => this._dateService.formatDate(date))
      .reduce((acc: { [date: string]: Event[] }, d: string) => {
        acc[d] = [];

        return acc;
      }, {});
  }

  set cellsToBeCopiedData(data: { agent: Agent; job: Job | null }) {
    this._cellsToBeCopiedData$.next(data);
  }

  get cellsToBeCopiedData$(): Observable<{ agent: Agent; job: Job | null; } | null> {
    return this._cellsToBeCopiedData$;
  }



}
