import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {Agent} from 'src/app/model/agent.model';
import {Day} from 'src/app/model/day.model';
import {Event, EventActivityLine} from 'src/app/model/event.model';
import {Job} from 'src/app/model/job.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {Service} from 'src/app/model/service.model';
import {Skill} from 'src/app/model/skill.model';
import {getStartOfWeekDate} from 'src/app/util/date.util';
import {httpObservableToPromise} from 'src/app/util/promise.util';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from '../admin/fetching.service';
import {DateService} from "../date.service";
import { NotificationService } from '../../service/admin/notification.service';
const EVENT_URL = '/api/event';

export interface DateFormatted {
  date: string;
  day_id: number;
}

@Injectable({
  providedIn: 'root',
})
export class PlanningService extends AbstractService<Event> {
  constructor(@Inject(FetchingService) _fetchingService: FetchingService,
              protected readonly _httpClient: HttpClient,
              private notificationService: NotificationService,
              private _dateService: DateService) {
    super(_fetchingService, _httpClient, EVENT_URL);
  }

  public getOnWeek$(operatingLocationId: number,
                    weekNumber: number,
                    year: number,
                    version: number |null = null,
                    agentId: number | null | undefined = null): Observable<Event[]> {
    this.setFetching(true);
    const startOfWeekDate: Date = getStartOfWeekDate(weekNumber, year);
    const formattedStartOfWeekDate = this._dateService.formatDate(startOfWeekDate);
    return this._httpClient
      .get<Event[]>(
        `${EVENT_URL}?date=${formattedStartOfWeekDate}&location=${operatingLocationId}&version=${version}${
          !!agentId ? `&agent_id=${agentId}` : ''
        }`
      )
      .pipe(
        catchError(catchErrorOnArray()),
        take(1),
        map((events: Event[]) => {
          return events.map(this._convertData);
        }),
        finalize(() => this.setFetching(false))
      );
  }

  public getOnWeekGlobal$(operatingLocationId: number,
                          weekNumber: number,
                          year: number,
                          version: number | null = null) {
    this.getOnWeek$(operatingLocationId, weekNumber, year, version)
      .pipe(take(1))
      .subscribe((data) => {
        this._globalData$.next(data);
      });
  }

  public saveEvent$(lines: EventActivityLine[],
                    agent: Agent,
                    site: OperatingSite,
                    job: Job,
                    skills: Skill[],
                    services: Service[],
                    dates: DateFormatted[],
                    comment: string | null,
                    version: number |null
  ): Promise<Event[]> {
    this.setFetching(true);

    const data: any = {
      dates: dates,
      user_id: agent.id,
      company_id: agent.company?.id,
      operating_site_id: site.id,
      job_id: job.id,
      skills: skills.map(({id}) => id) ?? [],
      services: services.map(({id}) => id) ?? [],
      activities: lines.map(
        ({workSchedule, activityType, startingDate, endingDate}) => {
          return {
            activity_type_id: activityType.id,
            work_schedule_id: workSchedule.id,
            starting_date: startingDate,
            ending_date: endingDate,
          };
        }
      ),
      comment: comment,
      version: version
    };
    const result = this._httpClient.post<Event[]>(`${EVENT_URL}`, data).pipe(
      take(1),
      map((data: Event[]) => data.map(this._convertData)),
      finalize(() => this.setFetching(false))
    );

    return httpObservableToPromise<Event[]>(result);
  }

  public saveEventGlobal$(
    lines: EventActivityLine[],
    agent: Agent,
    site: OperatingSite,
    job: Job,
    skills: Skill[],
    services: Service[],
    dates: DateFormatted[],
    comment: string | null,
    version: number |null,
    week: number 
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const events: Event[] = await this.saveEvent$(
          lines,
          agent,
          site,
          job,
          skills,
          services,
          dates,
          comment,
          version
        );
        this._globalData$.pipe(take(1)).subscribe((d) => {
          this._globalData$.next([...d, ...events]);
          if (agent.tel_perso != null || agent.mail_perso != null) {
            this.notificationService.saveNotif(agent.id, week).subscribe(
              response => {
                console.log('Notif saved successfully');
              },
              error => {
                console.error('Error saving notif:', error);
              }
            );
          }
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  public updateEvent$(
    lines: EventActivityLine[],
    id: number,
    agent: Agent,
    site: OperatingSite,
    job: Job,
    skills: Skill[],
    services: Service[],
    date: string,
    day: Day,
    comment: string | null,
    version: number |null,
  ): Promise<{ event: Event; created: Event[] }> {
    this.setFetching(true);

    const data: any = {
      id: id,
      date: date,
      day_id: day.id,
      user_id: agent.id,
      company_id: agent.company?.id,
      operating_site_id: site.id,
      job_id: job.id,
      skills: skills.map(({id}) => id) ?? [],
      services: services.map(({id}) => id) ?? [],
      activities: lines.map(
        ({id, workSchedule, activityType, startingDate, endingDate}) => {
          return {
            activity_type_id: activityType.id,
            work_schedule_id: workSchedule.id,
            starting_date: startingDate,
            ending_date: endingDate,
            ...(!!id && {id: id}),
          };
        }
      ),
      comment: comment,
      version: version
    };

    const result = this._httpClient
      .put<{ event: Event; created: Event[] }>(`${EVENT_URL}/${id!}`, data)
      .pipe(
        take(1),
        map((data: { event: Event; created: Event[] }) => {
          data.event = this._convertData(data.event);
          data.created = data.created.map(this._convertData);

          return data;
        }),
        finalize(() => this.setFetching(false))
      );

    return httpObservableToPromise<{ event: Event; created: Event[] }>(result);
  }

  public updateEventGlobal$(
    lines: EventActivityLine[],
    id: number,
    agent: Agent,
    site: OperatingSite,
    job: Job,
    skills: Skill[],
    services: Service[],
    date: string,
    day: Day,
    comment: string | null,
    version: number |null,
    week: number 
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const {event, created} = await this.updateEvent$(
          lines,
          id,
          agent,
          site,
          job,
          skills,
          services,
          date,
          day,
          comment,
          version
        );
        this._globalData$.pipe(take(1)).subscribe((d) => {
          const data: Event[] = d.map((e: Event) => {
            if (e.id == event.id) {
              return event;
            }

            return e;
          });
          this._globalData$.next([...data, ...created]);
          if (agent.tel_perso != null || agent.mail_perso != null) {
            this.notificationService.saveNotif(agent.id, week).subscribe(
              response => {
                console.log('Notif saved successfully');
              },
              error => {
                console.error('Error saving notif:', error);
              }
            );
          }
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  public duplicateEvents$(
    events: Event[],
    agents: Agent[]
  ): Promise<{
    events: Event[];
    already_present: { [date: string]: Agent[] };
  }> {
    this.setFetching(true);

    const data = {
      events: events.map(({id}) => id),
      agents: agents.map(({id}) => id),
    };

    const result = this._httpClient
      .post<{ events: Event[]; already_present: { [date: string]: Agent[] } }>(
        `${EVENT_URL}/duplicate`,
        data
      )
      .pipe(
        take(1),
        map(
          (data: {
            events: Event[];
            already_present: { [date: string]: Agent[] };
          }) => {
            data.events = data.events.map((d) => this._convertData(d));
            return data;
          }
        ),
        finalize(() => this.setFetching(false))
      );

    return httpObservableToPromise<{
      events: Event[];
      already_present: { [date: string]: Agent[] };
    }>(result);
  }

  public duplicateEventsGlobal$(
    eventsToDuplicate: Event[],
    agents: Agent[]
  ): Promise<{ [date: string]: Agent[] }> {
    return new Promise<{ [date: string]: Agent[] }>(async (resolve, reject) => {
      try {
        const {events, already_present} = await this.duplicateEvents$(
          eventsToDuplicate,
          agents
        );
        this._globalData$.pipe(take(1)).subscribe((d) => {
          this._globalData$.next([...d, ...events]);
          resolve(already_present);
        });
      } catch (err) {
        return reject(err);
      }
    });
  }

  public deleteMultiple$(
    {id}: Agent,
    year: number,
    week: number,
    version: number | null
  ): Promise<void> {
    this.setFetching(true);

    const result = this._httpClient.delete<void>(
      `${EVENT_URL}/multiple/${id}`,
      {
        body: {year, week, version},
      }
    );

    return httpObservableToPromise<void>(result);
  }

  protected _convertData({
                           id,
                           date,
                           day,
                           day_period,
                           starting_date,
                           comment,
                           ending_date,
                           hours_payed,
                           rh_errors,
                           site_status_errors,
                           user,
                           company,
                           job,
                           skills,
                           services,
                           version,
                           operating_site,
                           work_schedule,
                           activity_type,
                           is_versioned,
                           event_version_id,
                           event_version,
                         }: Event): Event {
    return new Event(
      id,
      date,
      starting_date,
      ending_date,
      hours_payed,
      comment,
      rh_errors,
      site_status_errors,
      day_period,
      day,
      user,
      company,
      operating_site,
      job,
      skills,
      services,
      version,
      work_schedule,
      activity_type,
      is_versioned,
      event_version_id,
      event_version
    );
  }
}
