<td class="td-agent-name mat-cell"
    colspan="140"
    [ngClass]="{ 'has-error': (hasRhErrors$ | async) || (hasSiteStatusPlanningErrors$ | async)}"
    (contextmenu)="onActionMenu($event)">

  <div style="display: flex; align-items: center; justify-content: space-between;">
    <span>{{ agent.getName() }}</span>
  </div>
</td>




<ng-container *ngIf="!fetching$ || (fetching$ | async)">
  <mat-progress-spinner diameter="30" color="accent" mode="indeterminate">
  </mat-progress-spinner>
</ng-container>

<ng-container *ngIf="!!agent && !!events && !!dates && !!fetching$ && !(fetching$ | async)">
  <ng-container *ngFor="let site of sites; first as isFirst; last as isLast">
    <ng-container
      *ngFor="
       let date of dates;
        let j = index;
        first as isFirst2;
        last as isLast2
      ">
      <ng-container
        *ngIf="
          events[date].morning.length > 0 ||
          events[date].day.length > 0 ||
          events[date].afternoon.length > 0
        ">
        <ng-template
          #eventsByPeriod
          [ngTemplateOutlet]="eventsByPeriod"
          let-morning="morning"
          let-day="day"
          let-afternoon="afternoon"
          [ngTemplateOutletContext]="events[date]">
          <ng-container *ngIf="morning.length > 0; else noMorning">
            <ng-container *ngFor="let event of morning; first as isFirst3">
              <ng-container *ngIf="
                  event | eventValid: site:jobSelected;
                  else morningUnavaible
                ">
                <td class="mat-cell"
                    matTooltipShowDelay="500"
                    matTooltipClass="line-broken-tooltip"
                    [matTooltip]="event | agentTooltip"
                    [style.background-color]="event | agentBackgroundColor"
                    [attr.colspan]="10 / (morning.length + day.length)"
                    (contextmenu)="onContextMenu($event, { event: event })"
                    (pointerdown)="selectCelToCopy($event, event)"
                    [class.is-selected]="selectedCellsToBeCopied.has(event.id)"
                    [class.left-border]="!isFirst && isFirst2 && isFirst3"
                    [class.resize-font]="event.activity_type.code.length > 2"
                    [class.has-comment]="event.comment">
                  {{ event.activity_type.code }}
                </td>
              </ng-container>

              <ng-template #morningUnavaible>
                <td class="mat-cell cell-unavailable"
                    [attr.colspan]="10 / (morning.length + day.length)"
                    (contextmenu)="
                    onContextMenu($event, {
                      site: site,
                      date: date,
                      day_period: 0
                    })
                  "
                    [class.left-border]="!isFirst && isFirst2 && isFirst3">

                </td>
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-template #noMorning>
            <td class="mat-cell"
                *ngIf="day.length == 0"
                [attr.colspan]="10"
                (contextmenu)="
                onContextMenu($event, {
                  site: site,
                  date: date,
                  day_period: 0
                })
              "
                [class.left-border]="!isFirst && isFirst2"
            ></td>
          </ng-template>

          <ng-container *ngIf="day.length > 0">
            <ng-container *ngFor="let event of day">
              <ng-container *ngIf="event | eventValid: site:jobSelected; else dayUnavaible">
                <td class="mat-cell"
                    matTooltipShowDelay="500"
                    matTooltipClass="line-broken-tooltip"
                    [matTooltip]="event | agentTooltip"
                    [style.background-color]="event | agentBackgroundColor"
                    (contextmenu)="onContextMenu($event, { event: event })"
                    (pointerdown)="selectCelToCopy($event, event)"
                    [class.is-selected]="selectedCellsToBeCopied.has(event.id)"
                    [attr.colspan]="
                    10 / (afternoon.length + day.length) +
                    10 / (morning.length + day.length)
                  "
                    [class.left-border]="
                    !isFirst && isFirst2 && morning.length == 0
                  "
                    [class.right-border]="
                    !isLast && isLast2 && afternoon.length == 0
                  "
                    [class.resize-font]="event.activity_type.code.length > 2"
                    [class.has-comment]="event.comment">
                  {{ event.activity_type.code }}
                </td>
              </ng-container>

              <ng-template #dayUnavaible>
                <td class="mat-cell cell-unavailable"
                    (contextmenu)="
                    onContextMenu($event, {
                      site: site,
                      date: date,
                      day_period: 2
                    })
                  "
                    [attr.colspan]="
                    10 / (afternoon.length + day.length) +
                    10 / (morning.length + day.length)
                  "
                    [class.left-border]="
                    !isFirst && isFirst2 && morning.length == 0
                  "
                    [class.right-border]="
                    !isLast && isLast2 && afternoon.length == 0
                  "
                ></td>
              </ng-template>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="afternoon.length > 0; else noAfternoon">
            <ng-container *ngFor="let event of afternoon; last as isLast3">
              <ng-container
                *ngIf="
                  event | eventValid: site:jobSelected;
                  else afternoonUnavailable
                ">
                <td class="mat-cell"
                    matTooltipShowDelay="500"
                    matTooltipClass="line-broken-tooltip"
                    [matTooltip]="event | agentTooltip"
                    [style.background-color]="event | agentBackgroundColor"
                    (contextmenu)="onContextMenu($event, { event: event })"
                    (pointerdown)="selectCelToCopy($event, event)"
                    [class.is-selected]="selectedCellsToBeCopied.has(event.id)"
                    [attr.colspan]="10 / (afternoon.length + day.length)"
                    [class.right-border]="!isLast && isLast2 && isLast3"
                    [class.resize-font]="event.activity_type.code.length > 2"
                    [class.has-comment]="event.comment">
                  {{ event.activity_type.code }}
                </td>
              </ng-container>

              <ng-template #afternoonUnavailable>
                <td class="mat-cell cell-unavailable"
                    [attr.colspan]="10 / (afternoon.length + day.length)"
                    (contextmenu)="
                    onContextMenu($event, {
                      site: site,
                      date: date,
                      day_period: 1
                    })
                  "
                    [class.right-border]="!isLast && isLast2 && isLast3">
                </td>
              </ng-template>
            </ng-container>
          </ng-container>

          <ng-template #noAfternoon>
            <td class="mat-cell"
                *ngIf="day.length == 0"
                [attr.colspan]="10"
                (contextmenu)="
                onContextMenu($event, {
                  site: site,
                  date: date,
                  day_period: 1
                })
              "
                [class.right-border]="!isLast && isLast2">
            </td>
          </ng-template>
        </ng-template>
      </ng-container>
      <ng-container
        *ngIf="
          events[date].morning.length == 0 &&
          events[date].day.length == 0 &&
          events[date].afternoon.length == 0
        ">
        <td class="mat-cell"
            colspan="10"
            (contextmenu)="
            onContextMenu($event, { site: site, date: date, day_period: 0 })
          "
            [ngClass]="{
            'is-selected': site.id == selectedOperatingSite?.id && selected.has(2 * j)
          }"
            (pointerover)="onPointerOver($event, 2 * j, site)"
            (pointerdown)="onPointerDown($event, 2 * j, site)"
            [class.left-border]="!isFirst && isFirst2">
        </td>
        <td class="mat-cell"
            colspan="10"
            (contextmenu)="
            onContextMenu($event, { site: site, date: date, day_period: 1 })
          "
            [ngClass]="{
            'is-selected':
              site.id == selectedOperatingSite?.id && selected.has(2 * j + 1)
          }"
            (pointerdown)="onPointerDown($event, 2 * j + 1, site)"
            [class.right-border]="!isLast && isLast2"
        ></td>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<td colspan="20" class="mat-cell hours-td">
  {{ hoursPayed | hsHours | numberPrecision }}
</td>
<td colspan="20" class="mat-cell hours-td">
  {{ hoursPayed | hnnHours | numberPrecision }}
</td>
<td colspan="20" class="mat-cell hours-td">
  {{ hoursPayed | hddHours | numberPrecision }}
</td>
<td colspan="20" class="mat-cell hours-td">
  {{ hoursPayed | TotalHours | numberPrecision }}
</td>
<app-activity-context-menu [contextMenuPosition]="contextMenuPosition"
                           [workSchedules]="workSchedules"
                           [sitesStatusPlanningByOperatingSite]="sitesStatusPlanningByOperatingSite"
                           [sitesStatus]="sitesStatus"
                           [activityTypes]="activityTypes"
                           [length]="selected.size"
                           [selectedCellsToBeCopied]="selectedCellsToBeCopied"
                           [job]="job"
                           (singleTDClicked$)="handleContextSingleTDClicked($event)"
                           (modifyEvent$)="handleActionOnEvent($event)"
                           (addLine$)="handleContextAddLine($event)">
</app-activity-context-menu>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition?.x"
     [style.top]="contextMenuPosition?.y"
     [matMenuTriggerFor]="userActions">
     
</div>

<mat-menu #userActions="matMenu">
  <div>
    <div fxLayout="column" fxLayoutAlign="center start">
      <button mat-button
              *ngIf="hasSiteStatusPlanningErrors$ | async"
              (click)="showSiteStatusPlanningErrors()"
              color="warn">
        <mat-icon>error</mat-icon>
        <span>Changement d'état site</span>
      </button>
      <button *ngIf="hasRhErrors$ | async"
              mat-button
              (click)="showRhErrors()"
              color="warn">
        <mat-icon>error</mat-icon>
        <span>Règles RH non respectées</span>
      </button>
      <button *ngIf="isLatestEventVersionSelected$ | async"
              mat-button
              color="primary"
              (click)="handleDuplicateEvents()">
        <mat-icon>content_copy</mat-icon>
        <span>Copier les horaires</span>
      </button>

      <button *ngIf="isLatestEventVersionSelected$ | async"
              mat-button
              color="primary"
              (click)="handleCopySelectedSchedules()">
        <mat-icon>content_copy</mat-icon>
        <span>Copier les horaires sélectionnés</span>
      </button>
      
      <button *ngIf="isLatestEventVersionSelected$ | async"
              mat-button
              color="warn"
              (click)="handleDeleteEvents()">
        <mat-icon>delete_outline</mat-icon>
        <span>Supprimer les horaires</span>
      </button>

      
    </div>
  </div>
</mat-menu>
