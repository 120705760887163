<mat-card>
    <mat-card-title>Paramètres SMS</mat-card-title>
    <mat-card-content>
      <form (ngSubmit)="onSave()" #smsSettingsForm="ngForm" class="sms-settings-form">
        <div class="form-grid">
          <!-- Left Column -->
          <div class="form-left">
            <!-- SMS Status -->
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Statut SMS</mat-label>
              <mat-select [(ngModel)]="smsSettings.sms_status" name="sms_status" required>
                <mat-option value="active">Activé</mat-option>
                <mat-option value="inactive">Désactivé</mat-option>
              </mat-select>
            </mat-form-field>
            <!-- API SMS -->
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>API SMS</mat-label>
              <input matInput [(ngModel)]="smsSettings.api_sms" name="api_sms" required>
            </mat-form-field>
          </div>
          <!-- Right Column -->
          <div class="form-right">
            <!-- Emetteur SMS -->
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Émetteur SMS</mat-label>
              <input matInput [(ngModel)]="smsSettings.emetteur_sms" name="emetteur_sms" required>
            </mat-form-field>
            <!-- SMS Sandbox -->
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Sandbox SMS</mat-label>
              <mat-select [(ngModel)]="smsSettings.sms_sandbox" name="sms_sandbox" required>
                <mat-option value="active">Activé</mat-option>
                <mat-option value="inactive">Désactivé</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- Submit Button -->
        <div class="form-actions">
          <button mat-raised-button color="primary" type="submit">Enregistrer</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  