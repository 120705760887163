import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TokenInterceptor} from 'src/app/interceptor/token.interceptor';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {MatModule} from './module/mat/mat.module';
import {LoginComponent} from './view/auth/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AgentComponent} from './view/administration/staff-management/agent/agent.component';
import {JobComponent} from './view/administration/staff-management/job/job.component';
import {SkillComponent} from './view/administration/staff-management/skill/skill.component';
import {CustomTableComponent} from './common/table/custom-table/custom-table.component';
import {AgentCreationDialogComponent} from './dialog/agent-creation-dialog/agent-creation-dialog.component';
import {ResetPasswordComponent} from './view/auth/reset-password/reset-password.component';
import {CustomInputComponent} from './common/input/custom-input/custom-input.component';
import {CustomSelectComponent} from './common/input/custom-select/custom-select.component';
import {CustomItemOptionPipe} from './pipe/custom-item-option.pipe';
import {RessourceDeletionDialogComponent} from './dialog/ressource-deletion-dialog/ressource-deletion-dialog.component';
import {JobCreationDialogComponent} from './dialog/job-creation-dialog/job-creation-dialog.component';
import {SkillCreationDialogComponent} from './dialog/skill-creation-dialog/skill-creation-dialog.component';
import {CompanyComponent} from './view/administration/company/company.component';
import {CompanyCreationDialogComponent} from './dialog/company-creation-dialog/company-creation-dialog.component';
import {CompanyServiceDialogComponent} from './dialog/company-service-dialog/company-service-dialog.component';
import {ServiceComponent} from './view/administration/company/service/service.component';
import {ServiceCreationDialogComponent} from './dialog/service-creation-dialog/service-creation-dialog.component';
import {DragDropTwoTablesComponent} from './common/table/drag-drop-two-tables/drag-drop-two-tables.component';
import {
  ServiceAgentAssignDialogComponent
} from './dialog/service-agent-assign-dialog/service-agent-assign-dialog.component';
import {
  CompanyAgentAssignDialogComponent
} from './dialog/company-agent-assign-dialog/company-agent-assign-dialog.component';
import {ForgotPasswordComponent} from './view/auth/forgot-password/forgot-password.component';
import {
  AlertEntityPresentDialogComponent
} from './dialog/alert-entity-present-dialog/alert-entity-present-dialog.component';
import {CustomSearchInputComponent} from './common/input/custom-search-input/custom-search-input.component';
import {ChangePasswordComponent} from './view/auth/change-password/change-password.component';
import {
  OperatingLocationCreationDialogComponent
} from './dialog/operating-location-creation-dialog/operating-location-creation-dialog.component';
import {OperatingLocationComponent} from './view/administration/site/operating-location/operating-location.component';
import {
  OperatingSiteCreationDialogComponent
} from './dialog/operating-site-creation-dialog/operating-site-creation-dialog.component';
import {OperatingSiteComponent} from './view/administration/site/operating-site/operating-site.component';
import {PlanningComponent} from './view/specific-view/planning/planning.component';
import {AgentRowComponent} from './view/specific-view/planning/by-job/agent-row/agent-row.component';
import {EventCreationDialogComponent} from './dialog/event-creation-dialog/event-creation-dialog.component';
import {ActivityTableComponent} from './dialog/event-creation-dialog/activity-table/activity-table.component';
import {CustomTextareaComponent} from './common/input/custom-textarea/custom-textarea.component';
import {RhErrorsDialogComponent} from './dialog/rh-errors-dialog/rh-errors-dialog.component';
import {PlanningDateDisplayPipe} from './pipe/planning-date-display.pipe';
import {TbodyJobComponent} from './view/specific-view/planning/by-job/tbody-job/tbody-job.component';
import {
  ResourcePlanificatorDialogComponent
} from './dialog/resource-planificator-dialog/resource-planificator-dialog.component';
import {SiteStatusPlanningComponent} from './view/specific-view/site-status-planning/site-status-planning.component';
import {TbodySiteComponent} from './view/specific-view/site-status-planning/tbody-site/tbody-site.component';
import {DraggableLegendComponent} from './common/legend/draggable-legend/draggable-legend.component';
import {
  ActivityContextMenuComponent
} from './view/specific-view/planning/activity-context-menu/activity-context-menu.component';
import {AgentTooltipPipe} from './pipe/planning/agent-tooltip.pipe';
import {AgentBackgroundColorPipe} from './pipe/planning/agent-background-color.pipe';
import {EventValidPipe} from './pipe/planning/event-valid.pipe';
import {AstreinteComponent} from './view/specific-view/astreinte/astreinte.component';
import {BankHolidayComponent} from './view/specific-view/bank-holiday/bank-holiday.component';
import {
  BankHolidayPlanningDialogComponent
} from './dialog/bank-holiday-planning-dialog/bank-holiday-planning-dialog.component';
import {
  BankHolidayPlanningDuplicateDialogComponent
} from './dialog/bank-holiday-planning-duplicate-dialog/bank-holiday-planning-duplicate-dialog.component';
import {
  AstreinteAgentRowComponent
} from './view/specific-view/astreinte/astreinte-agent-row/astreinte-agent-row.component';
import {
  SiteStatusHeaderRowComponent
} from './view/specific-view/planning/site-status-header-row/site-status-header-row.component';
import {AgentRowServiceComponent} from './view/specific-view/planning/by-service/agent-row/agent-row-service.component';
import {JobContextMenuComponent} from './view/specific-view/planning/job-context-menu/job-context-menu.component';
import {HsHoursPipe} from './pipe/planning/hs-hours.pipe';
import {HnnHoursPipe} from './pipe/planning/hnn-hours.pipe';
import {HddHoursPipe} from './pipe/planning/hdd-hours.pipe';
import {PlanningConsultComponent} from './view/specific-view/planning-consult/planning-consult.component';
import {NumberPrecisionPipe} from './pipe/number-precision.pipe';
import {YearsSelectedDisplayPipe} from './pipe/planning/years-selected-display.pipe';
import {
  AgentRowConsultComponent
} from './view/specific-view/planning-consult/agent-row-consult/agent-row-consult.component';
import {PlanningConsultCellPipe} from './pipe/planning/planning-consult-cell.pipe';
import {ExportExcelDialogComponent} from './dialog/export-excel-dialog/export-excel-dialog.component';
import {OperatingLocationNameDisplayPipe} from "./pipe/planning/operating-location-name-display.pipe";
import {DatePipe} from "@angular/common";
import {YearDatepickerComponent} from './common/year-datepicker/year-datepicker.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {WeekSelectComponent} from './common/week-select/week-select.component';
import {TotalHoursPipe} from "./pipe/planning/total-hours.pipe";
import {IndisponibiltiesComponent} from "./view/specific-view/indisponibilties/indisponibilties.component";
import {
  IndisponibiltiesAgentRowComponent
} from "./view/specific-view/indisponibilties/indisponibilties-agent-row/indisponibilties-agent-row.component";
import {
  IndisponibiltiesMenuComponent
} from "./view/specific-view/indisponibilties/indisponibilties-menu/indisponibilties-menu.component";
import {SiteStatusPlanningNameDisplayPipe} from "./pipe/planning/site-status-planning-name-display.pipe";
import {
  SiteStatusPlanningErrorDialog
} from "./dialog/site-status-planning-errors-dialog/site-status-errors-dialog.component";
import {WorkSchedulesBySiteStatusPipe} from "./pipe/work-schedules-by-site-status.pipe";
import {
  RecapTableEtatSiteLegendComponent
} from "./common/legend/recap-table-etat-site-legend/recap-table-etat-site-legend.component";
import { EmailEditComponent } from './view/administration/email-edit/email-edit.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SmsManagementComponent } from './view/administration/sms-management/sms-management.component';
import { EmailManagementComponent } from './view/administration/email-management/email-management.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    AgentComponent,
    JobComponent,
    SkillComponent,
    CustomTableComponent,
    AgentCreationDialogComponent,
    ResetPasswordComponent,
    CustomInputComponent,
    CustomSelectComponent,
    CustomItemOptionPipe,
    RessourceDeletionDialogComponent,
    JobCreationDialogComponent,
    SkillCreationDialogComponent,
    CompanyComponent,
    CompanyCreationDialogComponent,
    CompanyServiceDialogComponent,
    ServiceComponent,
    ServiceCreationDialogComponent,
    DragDropTwoTablesComponent,
    ServiceAgentAssignDialogComponent,
    CompanyAgentAssignDialogComponent,
    ForgotPasswordComponent,
    AlertEntityPresentDialogComponent,
    CustomSearchInputComponent,
    ChangePasswordComponent,
    OperatingLocationCreationDialogComponent,
    OperatingLocationComponent,
    OperatingSiteCreationDialogComponent,
    OperatingSiteComponent,
    PlanningComponent,
    AgentRowComponent,
    EventCreationDialogComponent,
    ActivityTableComponent,
    CustomTextareaComponent,
    RhErrorsDialogComponent,
    SiteStatusPlanningErrorDialog,
    PlanningDateDisplayPipe,
    WorkSchedulesBySiteStatusPipe,
    TbodyJobComponent,
    ResourcePlanificatorDialogComponent,
    SiteStatusPlanningComponent,
    TbodySiteComponent,
    DraggableLegendComponent,
    RecapTableEtatSiteLegendComponent,
    ActivityContextMenuComponent,
    AgentTooltipPipe,
    AgentBackgroundColorPipe,
    EventValidPipe,
    AstreinteComponent,
    BankHolidayComponent,
    BankHolidayPlanningDialogComponent,
    BankHolidayPlanningDuplicateDialogComponent,
    AstreinteAgentRowComponent,
    SiteStatusHeaderRowComponent,
    AgentRowServiceComponent,
    JobContextMenuComponent,
    HsHoursPipe,
    HnnHoursPipe,
    HddHoursPipe,
    TotalHoursPipe,
    PlanningConsultComponent,
    NumberPrecisionPipe,
    YearsSelectedDisplayPipe,
    AgentRowConsultComponent,
    PlanningConsultCellPipe,
    ExportExcelDialogComponent,
    OperatingLocationNameDisplayPipe,
    YearDatepickerComponent,
    WeekSelectComponent,
    IndisponibiltiesComponent,
    IndisponibiltiesAgentRowComponent,
    IndisponibiltiesMenuComponent,
    SiteStatusPlanningNameDisplayPipe,
    EmailEditComponent,
    SmsManagementComponent,
    EmailManagementComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    EditorModule,
    MatRadioModule  
  ],
  providers: [
    Title,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
